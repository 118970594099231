import Head from 'next/head';
import { ikImg } from '../core/factories/image.factory';

export type CommonMetaHeadProps = {
  title: string;
  description: string;
  image?: string;
  imageAlt?: string;
  schemaOrgAttributes?: any;
};

const OG_DESCRIPTION_WARN_LENGTH = 160;

export const CommonMetaHead = ({ title, description, image, imageAlt, schemaOrgAttributes }: CommonMetaHeadProps) => {
  // Log a warning if the description provided is too long
  if (description?.length > OG_DESCRIPTION_WARN_LENGTH) {
    // todo reenable logging when a better length is identified; the current is non-standard
    // console.warn(`og:description tag is longer than ${OG_DESCRIPTION_WARN_LENGTH} characters:\n${description}`);
  }

  // Note: the <link> and og:url attributes are defined on _app.tsx based on the canonical path
  return (
    <Head>
      <title>{title}</title>
      <meta property="description" content={description} key="description" />

      {/* OpenGraph properties */}
      <meta property="og:title" content={title} key="og-title" />
      <meta property="og:description" content={description} key="og-description" />
      <meta
        property="og:image"
        /* Fall back to a 1200x630 logo (og:image standard size) */
        content={image || ikImg('/benefeature/logo-black-1200x630.jpg')}
        key="og-image"
      />
      <meta
        property="og:image:alt"
        /* Fall back to the title itself, or failing that, a basic description of the logo itself */
        content={imageAlt || title || 'Benefeature large logo'}
        key="og-image-alt"
      />

      {/* Schema.org markup */}
      {schemaOrgAttributes ? (
        <script type="application/ld+json">{JSON.stringify(schemaOrgAttributes, null, 2)}</script>
      ) : null}
    </Head>
  );
};
