import { styled } from 'baseui';

/* Creates a full-width element which shows a horizontal rule with the word "or" centered horizontally and vertically, as in: <br>
 * ---- or ---- */
export const BeStyledOrHr = styled('hr', ({ $theme }) => ({
  width: '80%',
  border: 0,
  borderTop: `1px solid ${$theme.colors.borderOpaque}`,
  display: 'block',
  margin: '2rem auto 1rem',
  overflow: 'visible',
  textAlign: 'center',
  ':before': {
    background: $theme.colors.backgroundPrimary,
    color: $theme.colors.contentTertiary,
    content: '"or"',
    padding: '0 0.4rem',
    position: 'relative',
    top: '-0.9rem',
  },
}));

/* Creates a full-width element which shows a horizontal rule, as in: <br>
 * ------------ */
export const BeStyledHr = styled('hr', ({ $theme }) => ({
  width: '80%',
  border: 0,
  borderTop: `1px solid ${$theme.colors.borderOpaque}`,
  display: 'block',
  margin: '2rem auto 1rem',
  overflow: 'visible',
  textAlign: 'center',
}));
