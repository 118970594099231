import React from 'react';
import { Input, InputProps } from 'baseui/input';
import { FieldProps } from 'formik/dist/Field';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { mergeOverrides, useStyletron } from 'baseui';

export type BeInputProps = InputProps &
  Partial<FieldProps> & {
    isAccented?: boolean;
  };

export const BeInput = ({ field, meta, form, overrides, isAccented, ...props }: BeInputProps) => {
  const [css, $theme] = useStyletron();
  const backgroundCss = isAccented
    ? {
        backgroundColor: $theme.colors['backgroundLightAccent'],
        color: $theme.colors.accent,
      }
    : {
        backgroundColor: $theme.colors.backgroundTertiary,
      };

  return (
    <Input
      name={field?.name}
      value={field?.value}
      error={meta?.touched && (meta?.error as any)}
      onChange={field?.onChange}
      onBlur={field?.onBlur}
      overrides={mergeOverrides(overrides as any, {
        Root: {
          style: ({ $theme, $isFocused }: any) => ({
            paddingRight: '0px',
            paddingLeft: '0px',
            ...($isFocused
              ? {
                  backgroundColor: $theme.colors['backgroundLightAccent'],
                }
              : backgroundCss),
          }),
        },
        Input: {
          style: ({ $theme, $isFocused }: any) => ({
            ...($isFocused
              ? {
                  backgroundColor: $theme.colors['backgroundLightAccent'],
                }
              : backgroundCss),
          }),
        },
        EndEnhancer: {
          style: ({ $theme, $isFocused }: any) => ({
            ...($isFocused
              ? {
                  backgroundColor: 'transparent',
                }
              : backgroundCss),
          }),
        },
        StartEnhancer: {
          style: ({ $theme, $isFocused }: any) => ({
            ...($isFocused
              ? {
                  backgroundColor: 'transparent',
                }
              : backgroundCss),
          }),
        },
        MaskToggleButton: {
          style: ({ $theme, $isFocused }: any) => ({
            ...($isFocused
              ? {
                  backgroundColor: 'transparent',
                }
              : backgroundCss),
          }),
        },
        ClearIconContainer: {
          style: ({ $theme, $isFocused }: any) => ({
            ...($isFocused
              ? {
                  backgroundColor: 'transparent',
                }
              : backgroundCss),
          }),
        },
        ClearIcon: {
          style: ({ $theme }) => ({
            width: '18px',
            height: '18px',
            color: $theme.colors.accent,
          }),
        },
      })}
      {...props}
    />
  );
};

export const BePositiveInput = ({ ...props }: BeInputProps) => {
  const isValid = props.meta?.touched && !props.meta?.error;
  const style = {
    backgroundColor: '#E8F0FE',
  };
  return (
    <BeInput
      endEnhancer={isValid ? () => <DoneRoundedIcon /> : undefined}
      positive={isValid}
      overrides={{
        InputContainer: { style },
        EndEnhancer: { style },
      }}
      {...props}
    />
  );
};
